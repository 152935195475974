"use client"; // Error components must be Client Components

import SomethingWentWrong from "@/components/Pages/SomethingWentWrong";
import { useEffect } from "react";
import { reportError } from "@/utility/error";

export default function Error({ error, reset }) {
  useEffect(() => {
    console.log(error);
    // Log the error to an error reporting service
    reportError(error, "/app/(user)/error");
  }, [error]);

  return (
    <div>
      <SomethingWentWrong error={error} reset={reset} />
    </div>
  );
}
