import { ADD_ERROR } from "../api/apiUrl";

const reportError = async (error, dir) => {
  if (process.env.NODE_ENV === "development" || !error) {
    return;
  }
  try {
    await fetch(`${process.env.NEXT_PUBLIC_API_PATH}${ADD_ERROR}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        error,
        dir,
      }),
    });
  } catch (error) {
    console.log(error);
  }
};

export { reportError };
