"use client";
import Image from "next/image";
import Link from "next/link";

const SomethingWentWrong = ({ error, reset }) => {
  console.error(error);
  return (
    <div className="something-went-wrong">
      <Image
        loading="lazy"
        src="/new_assets/images/wrong.jpeg"
        width={1400}
        height={934}
        className="image"
        alt="wrong"
      />
      <div className="overlay">
        {" "}
        <i className="fa fa-solid fa-triangle-exclamation icon fa-5x" />
        <h2>Something went wrong!</h2>
        <button onClick={() => reset()} className="btn btn-secondary">
          <i className="fa fa-refresh" /> Try again
        </button>
        <br />
        <Link href="/">Back to homepage</Link>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
