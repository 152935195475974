// Auth URL
export const REGISTER = "auth/register";
export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";

// Home
export const GET_YOUTUBE_LINK = "admin/getYoutubeLink";
export const ADD_NEWSLETTER = "users/addNewsLetter";
export const GET_NEWSLETTER_IMG = "admin/getNewsletter";
export const SEND_MAIL_BY_CONTACTUS = "users/contactUs";

// Packages
export const GET_ALL_PACKAGES = "admin/getAllPackages";
export const STRIPE_PAYMENT = "users/stripePayment";
// export const SHOPIFY_PAYMENT = '/';
export const SQUAREPAYMENT_FOR_PACKAGE = "users/squarepay";
export const getAllplanBypackage = "users/getAllplanBypackage";
export const getAllplanByorder = "users/getPlanByOrder";
export const Coppy_Plan = "admin/copyPlan";
export const Add_Workout_Plan = "admin/addworkout";
export const Get_All_Workout_Plan = "admin/getAllWorkoutPlan";
export const Get_Single_Workout_Plan = "admin/getSingleWorkoutPlan";
export const Edit_Workout_Plan = "admin/editWorkoutPlan";
export const Remove_Workout_Plan = "admin/deleteWorkout";
export const PAYPAL_PAYMENT_PACKAGE = "users/paypalPayment";
export const PAYPAL_PAYMENT_CLASS = "admin/paypalCreate";

//Admin
export const ADMIN_PAYMENT_TRACKING = "admin/paymentTracking";
export const ADMIN_ADD_PAYMENT_TO_COACH = "admin/addPaymentTracking";
export const GET_USER_HISTORY = "admin/get_user_history";
export const GET_SINGLE_INVOICE = (id) => `/admin/getSingleTransaction/${id}`;

// User
export const GET_SINGLE_USER_DATA = (user_id) =>
  `users/getSingleUser/${user_id}`;
export const GET_ALL_USERS = `users/allUsers`;

// Coaches
export const GET_ALL_COACHES = "users/allCoach";
export const ADMIN_ASSIGN_CAOCH = "admin/assignCoach";
export const GET_REWARDS_API = "users/getrewards";
export const DELETE_COACH = "admin/delete-coach/";

// Chat
export const GET_ALL_CHAT_USERS = "chat/getAllUsers";
export const START_CHAT = "chat/start";
export const GET_ALL_CHAT_MESSAGES = "chat/messages";
export const SAVE_CHAT_FILE = "chat/save/file";
export const SEND_MESSAGE = "chat/sendMessages";
export const GET_CHAT_MESSAGE = "chat/getMessages";
export const UPLOAD_CHAT_FILES = "chat/uploadImageInChat";
export const DOWNLOAD_CHAT_FILES = "chat/download";
export const READ_MESSAGE_CHAT = "chat/readMessage";

//class
export const Add_Class_Plan = "admin/addclass";
export const Get_All_Class_Plan = "admin/getAllClasses";
export const GET_ALL_SPECIFIC_CLASSES = (type, user_id) =>
  `/admin/getAllSpecificClasses?type=${type}&user_id=${user_id}`;
export const GET_SINGLE_CLASS_DATA = (class_id) =>
  `/admin/getSingleClass/${class_id}`;
export const REMOVE_CLASS_DATA = (class_id) => `/admin/deleteclass/${class_id}`;
export const EDIT_CLASS_PLAN = (class_id) => `/admin/editclass/${class_id}`;
export const CLASS_BOOKING_PAYMENT = "admin/BookingClass";
export const CLASS_BOOKING_SQUARE_PAYMENT = "admin/squareClassPay";
export const UPDATE_TIME_ZONE = "admin/UpdateTimezone";

// Coach Availability
export const GET_COACH_AVAILABILITY = (coach, classType) =>
  `/users/coachAvailability?coach_id=${coach}&type=${classType}`;
export const SAVE_COACH_AVAILABILITY = "users/coachAvailability";
export const GET_ALL_SPECIFIC_COACH = "users/getTypeAvailability";
export const GET_ALL_COACH_AVALABILITY = "users/getAllAvailability";
export const GET_ALL_BOOKING_BY_MONTH = "admin/userBookingsByMonth";

//Zoom
export const ZoomConnect_API = "users/zoomConnect";

//Review
export const GET_ALL_Reviews = "admin/getAllReviewPoints";
export const PUBLISH_UNPUBLISH = "admin/PublishReviewPoints";
export const GET_PUBLISH_RECORD = "admin/getPublishReview";
export const CHECK_REVIEW_STATUS = "users/checkReview";

//rewards
export const GET_ALL_REWARD_OFFER = "admin/get_all_rewards_offer";
export const ADD_REWARD_OFFER = "admin/add_rewards_offer";
export const UPLOAD_REWARD_OFFER_IMG = "admin/rewards_offer_image";
export const EDIT_REWARD_OFFER = "admin/edit_rewards_offer";
export const GET_SINGLE_REWARD_OFFER = "admin/get_single_rewards_offer";
export const REMOVE_REWARD_OFFER = "admin/delete_rewards_offer";
export const GET_ALL_REFRRAL_AMOUNT = "users/addRedeem";
export const REDEEM_REFRRAL_AMOUNT = "users/addRedeem";
export const REDEEM_REQUEST_APPROVE = "admin/approveRedeem";
export const USER_GET_REWARD_OFFER = "users/getAllRewardsOffer";
export const REDEEEM_REWARD_OFFER = "users/addRedeemOffers ";
export const GETALL_REDEEEM_OFFER_REQUEST = "users/getAllRedeemsOffer ";
export const APPROVE_REDEEM_OFFER = "admin/approveRedeemOffer ";
export const ASSIGN_OFFER_TO_USER = "admin/addOfferAssign ";
export const GET_SINGLE_ASSIGN_DATA = (id) =>
  `/admin/getsingleOfferAssign/${id}`;
export const UPDATE_ASSIGN_OFFER_DATA = (id) =>
  `/admin/updateOfferAssign/${id}`;
export const REMOVE_ASSIGN_DATA = (id) => `/admin/delOfferAssign/${id}`;

export const GET_ALL_NEWSLETTER = "admin/getAllNewsLetter ";
export const ADD_QUIZ_QUESTION = "admin/addQuestions";
export const GET_ALL_QUIZ_QUESTION = "admin/getAllQuestions";
export const REMOVE_QUESTION_DATA = (id) => `/admin/deleteQuestions/${id}`;
export const GET_SINGLE_QUESTION_DATA = (id) =>
  `/admin/getSingleQuestions/${id}`;
export const EDIT_QUESTION_DATA = (id) => `/admin/editQuestions/${id}`;
export const GET_QUIZ_QUESTION = "users/getque_acc_date";
export const SUBMIT_ANSWER = "users/addAnswer";
export const GET_ALL_ANSWER = "users/getAnswer";
export const GET_ALL_LEVEL_REWARD_OFFER = "users/rewardsOffer";
export const DELETE_INVOICE = "admin/delete-invoice/";
export const DELETE_REVIEW = "admin/delete-review/";

//Bank Detail
export const ADD_BANK_DETAILS = "users/addBankDetails";
export const GET_BANK_DETAILS = "users/getBankDetails";

//challenge
export const ADD_CHALLENGE = "users/addchallenge";
export const GET_CHALLENGE = (id) => `/users/getSingleChallenge/${id}`;
export const UPDATE_CHALLENGE = "users/UpdateChallenge";
export const ACCEPT_CHALLENGE = "users/accept_challenge";
export const APPROVE_CHALLENGE = "users/approve_challenge";
export const ADD_GOAL = "users/add_goal";
export const GET_GOAL = (id) => `/users/get_goal/${id}`;
export const APPROVE_GOAL = "users/approve_goal";

export const copy_workout_plan = "admin/copyWorkoutPlan";
export const GET_REWARD_POINT_EXPIRY = "users/Rewardpointexpiry";
export const CHECK_USER_PLAN_STATUS = "users/check_active_plan";

//Blog
export const GET_ALL_BLOGS = "blog/get-all-blogs";
export const GET_BLOG = "blog/get-blog";
export const CREATE_BLOG = "blog/create-blog";
export const UPDATE_BLOG = "blog/update-blog";
export const DELETE_BLOG = "blog/delete-blog";
export const ADD_CATEGORY = "blog/add-category";
export const GET_ALL_CATEGORIES = "blog/get-all-categories";
export const ADD_TAG = "blog/add-tag";
export const GET_ALL_TAGS = "blog/get-all-tags";
export const UPDATE_BLOG_IMAGE = "blog/update-blog-image";
export const ADD_COMMENT = "blog/add-comment";
export const DELETE_COMMENT = "blog/delete-comment";
export const GET_USER_BLOG = "blog/get-user-blog";
export const GET_ALL_USER_BLOGS = "blog/get-all-user-blogs";
export const PUBLISH_BLOG = "blog/publish-blog";
export const GET_BLOG_METADATA = "blog/get-blog-metadata";
export const APPROVE_COMMENT = "blog/approve-comment";

//Error
export const ADD_ERROR = "errors/add-error";

//transformation
export const GET_ALL_TRANSFORMATIONS = "transformation/get-all";
export const GET_PUBLISHED_TRANSFORMATIONS = "transformation/get-published";
export const ADD_TRANSFORMATION = "transformation/add";
export const DELETE_TRANSFORMATION = (id) => `transformation/delete/${id}`;
export const EDIT_TRANSFORMATION = "transformation/edit";
export const UPDATE_TRANSFORMATION_IMAGE = "transformation/update-image";
